.imageview-host {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  background-color: rgba(0, 0, 0, .1);

  .imageview-holder {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }

  .imageview-holder,
  .imageview-image {
    animation: imageview-show 320ms;
  }
}

@keyframes imageview-show {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.footer-links {
  display: flex;
  align-items: center;
  justify-content: center;
  // position: absolute;
  // bottom: 24px;
  // left: 50%;
  // transform: translateX(-50%);
  width: 100%;
  margin: 0;

  a {
    font-weight: 700;
    font-size: 16px;
    color: #fff;
    line-height: 1.5;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    margin: 0 20px;

    &:hover {
      opacity: .6;
    }
  }

}


// ---------------------------------------------------------

$control-color: #30caff;

.mask_editor_screen * {
  box-sizing: border-box;
}

.mask_editor_screen {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  margin: 0 auto;

  .controlbox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    max-width: 480px;
    width: 100%;
    margin: 0 auto 52px;

    .block-score {
      position: relative;
    }

    .control_name,
    .control_value {
      font-weight: 700;
      font-size: 24px;
      position: absolute;
      top: 16px;
      padding: 0;
      color: rgba(255, 255, 255, 0.6);
    }

    .control_name {
      left: 0;
    }

    .control_value {
      right: 0;
    }

    .control_holder {
      flex-grow: 1;
      width: 100%;

      input[type=range] {
        display: block;
        width: 100%;
        height: 8px;
        border-radius: 8px;
        background-color: #fff;
        -webkit-appearance: none;
        padding: 0;

        &::-webkit-slider-runnable-track {
          width: 100%;
          cursor: pointer;
          border-radius: 8px;
          background-color: #fff;
          height: 8px;
          padding: 0;
        }

        &::-webkit-slider-thumb {
          width: 24px;
          height: 24px;
          border: solid 4px #000;
          background-color: #01e777;
          cursor: pointer;
          -webkit-appearance: none;
          position: relative;
          z-index: 10;
          border-radius: 50%;
          margin-top: -8px;
        }

        &::-moz-range-track {
          width: 100%;
          cursor: pointer;
          border-radius: 8px;
          background-color: #fff;
          height: 8px;
          padding: 0;
        }

        &::-moz-range-thumb {
          width: 24px;
          height: 24px;
          border: solid 4px #000;
          background-color: #01e777;
          cursor: pointer;
          border-radius: 50%;
          position: relative;
          z-index: 10;
          margin-top: -12px;
        }

        &::-moz-range-progress {
          background-color: #43e5f7;
        }
      }
    }
  }

  .toolbox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 480px;
    width: 100%;
    margin: 0 auto 40px;

    .buttons-container {
      display: flex;
      align-items: center;

      .tool-save {
        display: none;
      }
    }

    button {
      display: flex;
      background: none;
      position: relative;
      margin-right: 48px;

      &:last-child {
        margin: 0;
      }

      svg {
        height: 40px;
        fill: #fff;
      }

      &:disabled {
        opacity: .6;
      }

      &.active {
        svg {
          fill: #01e777;
        }
      }

      span {
        color: rgba(255, 255, 255, 0.6);
        position: absolute;
        bottom: -24px;
        left: 50%;
        transform: translateX(-50%);
        line-height: 1.5;
        width: 100%;
      }
    }
  }

  .tool-save {
    font-weight: 700;
    font-size: 20px;
    line-height: 1.2;
    letter-spacing: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    width: 160px;
    height: 64px;
    border-radius: 40px;
    background-color: #01e777;
    text-transform: uppercase;
    cursor: pointer;
    margin: 0 auto;

    &:hover,
    &:active {
      opacity: .6;
    }
  }

  .canvas_holder {
    background: #1a1a1a;
    flex-grow: 1;
    // height: 100%;
    // max-height: 50vh;
    position: relative;
    margin-bottom: 32px;

    canvas {
      display: block;
    }

    .cursor {
      pointer-events: none;
      position: absolute;
      border-radius: 100%;
      opacity: .5;
      z-index: 10;

      &.pen {
        background: #01e777;
      }
      &.eraser {
        background: #fff;
      }
    }

    .lower {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      pointer-events: none;
    }

    .upper {
      position: relative;
      z-index: 2;
      opacity: 0;
    }
  }

  .active-range {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-radius: 8px;
    background-color: #01e777;
    z-index: 0;
  }
}

.tool-size-indicator {
  position: absolute;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  box-sizing: border-box;

  svg {
    max-width: 16px;
    width: 100%;
    fill:rgb(47, 61, 250);
  }
}

@media all and (max-width: 560px) {
  input[type=range] {
    height: 4px;

    &::-webkit-slider-thumb {
      width: 16px;
      height: 16px;
      border: solid 2px #000;
      margin-top: -6px;
    }

    &::-moz-range-thumb {
      width: 16px;
      height: 16px;
      border: solid 4px #000;
      margin-top: -6px;
    }
  }

  .mask_editor_screen {
    position: relative;
    padding-top: 48px;

    .controlbox {
      width: 100%;
      padding: 0 16px;
      margin: 0 auto 40px;

      .control_name,
      .control_value {
        font-size: 16px;
      }

      .control_name {
        left: 16px;
      }

      .control_value {
        right: 16px;
      }
    }

    .toolbox {
      padding: 0 16px;
      margin-bottom: 22px;

      button {
        margin-right: 32px;
        &:last-child {
          margin: 0;
        }
        svg {
          height: 32px;
        }

        span {
          font-size: 12px;
          top: 50%;
          bottom: auto;
          transform: translateY(-50%);
        }
      }

      .tool_undo {
        span {
          right: calc(100% + 8px);
          left: auto;
        }
      }

      .tool_redo {
        span {
          left: calc(100% + 8px);
        }
      }

      .buttons-container-arrow {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }

      .buttons-container-tools {
        width: 100%;
        position: relative;

        .tool-save {
          display: flex;
          background-color: #01e777;
          position: absolute;
          right: 0;
          width: 88px;
        }
      }

      .tool-viewmode {
        order: 3;
      }
    }

    .tool-save {
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: 1.6px;
      height: 48px;
      display: none;
    }
  }
}

// ---

.btn-choice-tab--celebrity { background-image: url(/assets/images/tabs/celebrity.jpg); }
.btn-choice-tab--prequel { background-image: url(https://assets.photo-cdn.net/npp/previews/prequel_6455_6683.jpg); }
.btn-choice-tab--gloss { background-image: url(https://assets.photo-cdn.net/npp/previews/gloss_6868.jpg); }
.btn-choice-tab--honeyvector { background-image: url(https://assets.photo-cdn.net/npp/previews/honeyvector_6819_3757.jpg); }
.btn-choice-tab--photolab_tab_ad { background-image: url(/assets/images/tabs/photolab_tab_ad2.png); }
.btn-choice-tab--pro_promotion { background-image: url(/assets/images/tabs/pro.png); }
.btn-choice-tab--pro { background-image: url(https://assets.photo-cdn.net/npp/previews/celebrity_6756_1978_2856.jpg); }
