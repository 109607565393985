$window-inner-height:  var(--window-inner-height);

.main-page {
    // min-height: 100vh;
    box-sizing: border-box;
    padding: 80px 0 24px;

    .container {
        display: flex;
        flex-direction: column;
    }
}

.logo {
    font-weight: 700;
    font-size: 40px;
    line-height: 1.2;
    text-align: left;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    box-sizing: border-box;
    margin-bottom: 40px;
}

.safe-and-cool {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      text-align: center;
      padding: 20px 0;
      font-size: 20px;
      font-weight: 700;
      color: black;
      background-color: #01e777;
      background-image: linear-gradient(to right, #01e777 43%, #01bbe4 100%);
    }

    svg {
        width: 24px;
        margin-left: 16px;
    }

    span {
      text-decoration: underline;
    }
}

@media all and (max-width: 900px) {
    .safe-and-cool {
        a {
            font-size: 16px;
            padding: 12px 0;
        }
    }
}

.main-title {
    font-weight: 700;
    font-size: 80px;
    line-height: 1.2;
    text-align: left;
    max-width: 750px;
    text-shadow: 0 0 8px #000;
    margin-bottom: 32px;
}

.main-subtitle {
    font-size: 40px;
    line-height: 1.6;
    text-align: left;
    max-width: 590px;
    margin-bottom: 48px;
}

.wr-slider {
    // flex-grow: 1;
    position: relative;
    margin-top: 6vh;
    margin-bottom: 6vh;
}

.wr-slider img {
    width: 100%;
}

.wr-slider .slick-list,
.wr-slider .slick-slider {
    position: relative;
}

.wr-slider .slick-dots li {
    width: 4px;
    height: 4px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    vertical-align: middle;
    margin: 0 8px;
}

.wr-slider .slick-dots button {
    width: 100%;
    height: 100%;
    margin: 0;
}

.wr-slider .slick-dots button {
    background-color: #fff;
    border-radius: 3px;
}

.wr-slider .slick-dots .slick-active button {
    background-color: #fff;
}

.wr-slider .slick-dots .slick-active {
    width: 30px;
}

.wr-slider .slick-dots {
    bottom: -40px;
}

.slide {
    display: flex;
    border-radius: 16px;
    width: 200px;
    height: 200px;
    box-sizing: border-box;
    overflow: hidden;
    transition: .6s;
    margin: 0 auto;

    img {
        width: 100%;
        height: 100%;
    }
}


// .slick-slide {
//     margin: 0 15px;
// }

// .slick-list {
//     margin: 0 -15px;
// }

.slide-item {
    margin: 0 7px 14px;
}

.slide-item h2 {
    font-size: 16px;
    font-weight: 700;
    font-style: oblique;
    line-height: 1;
    text-align: center;
    color: #fff;
    margin-bottom: 10px;
}

.slide-image-container {
    max-width: 140px;
    border-radius: 16px;
    overflow: hidden;
}

.slide-image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.slide-item-active {
    position: relative;
}

.slide-item-active .slide-image-container {
    box-shadow: 0 0 120px 0 #7ecd21;
    border: 2px solid #7ecd21;
    box-sizing: border-box;
    max-width: 200px;
}

.btn-upload-foto {
    font-size: 24px;
    font-weight: 700;
    color: #000;
    text-transform: uppercase;
    max-width: 320px;
    width: 100%;
    height: 80px;
    border-radius: 100px;
    background-color: #01e777;
    display: block;
    flex-shrink: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    margin-bottom: 80px;

    &:active {
        opacity: .6;
    }
}

.btn-another-foto {
    font-size: 15px;
    font-weight: 700;
    color: #f2bc19;
    display: flex;
    align-items: center;
    background-color: transparent;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;
    &:hover {
        opacity: .6;
    }
}

.btn-change-mask {
    width: 32px;
    height: 32px;
    background: none;
    flex-shrink: 0;
}

.creative-container {
    display: flex;
    align-items: center;
    justify-content: space-between  ;
    max-width: 694px;
    height: 54px;
    width: 100%;
    margin: 0 auto 24px;
}

.rules-list {
    &-container {
        font-size: 24px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        color: rgba(255, 255, 255, 0.6);
        max-width: 480px;
        margin-bottom: 120px;
    }

    &-title {
        font-weight: 700;
        margin-bottom: 16px;
    }

    &-item {
        position: relative;
        padding-left: 24px;
        margin-bottom: 16px;

        &:before {
            content: '';
            display: inline-block;
            width: 8px;
            height: 8px;
            background-color: #858585;
            border-radius: 50%;
            position: absolute;
            left: 0;
            top: 15px;
        }

        &:last-child {
            margin-bottom: 0;
        }

        span {
            font-weight: 700;
            text-transform: uppercase;
        }
    }
}

.main-info-container {
    display: flex;
    border-radius: 64px;
    background-color: #212121;
    padding: 40px 40px 40px 0;
    margin-bottom: 123px;

    .info-logo {
        width: 296px;
        position: relative;
        left: -32px;
        margin-right: 64px;
    }
}

.main-info-content {
    .info-image {
        height: 64px;
        margin-right: 24px;
    }

    p {
        font-size: 40px;
        line-height: 1.6;
    }
}

.main-info-content-header {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    p {
        font-weight: 700;
        font-size: 24px;
        line-height: 1.67;
    }
}

.main-video-container {
    position: absolute;
    top: 0;
    right: 0;
    width: 645px;
    z-index: -5;
    overflow: hidden;

    &:before {
        content: '';
        display: block;
        padding-top: 123%;
    }

    @media all and (max-width: 1300px) {
        width: 510px;
    }

    @media all and (max-width: 1000px) {
        width: 420px;
    }

    .main-video {
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.main-arrow-icon {
    width: 416px;
    position: absolute;
    left: 320px;
    bottom: -60px;
}

.create-page h2 {
    font-weight: 700;
    color: #333;
    line-height: 1.38;
    text-align: center;
    margin-bottom: 20px;
}

.create-page .create-steps {
    line-height: 1.38;
    text-align: center;
    color: #999;
    margin-bottom: 8px;
}

.creatives {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 30px;
}

.creative-holder {
    position: relative;
    width: 100%;
    flex-grow: 1;
    max-width: 694px;
    background-color: #212121;
    margin: 0 auto 8px;

    p {
        width: 100%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        text-align: center;
    }

    &:after {
        content: '';
        display: block;
        // padding-top: 100%;
    }
}

.creative {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    width: 100%;
    // height: fit-content;
    // width: fit-content;
    // max-width: 100%;
    // max-height: 100%;
}

.creative .num-check-foto {
    font-size: 10px;
    font-weight: 700;
    color: #fff;
    position: absolute;
    top: 8px;
    right: 8px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: #05b0ff;
    display: none;
    align-items: center;
    justify-content: center;
}

.creative .holder {
    position: relative;
    margin-bottom: 8px;
    padding-top: 100%;
    background-color: #eeeeee;
}

.creative.active .num-check-foto {
    display: flex;
}

.creative img {
    height: 100%;
    width: 100%;
    border-radius: 2px;
    margin: 0 auto;
    display: block;
    object-fit: contain;
}

.creative.active img {
    // box-shadow: 0 0 40px 0 #c77dff;
    border: solid 3px #05b0ff;
    box-sizing: border-box;
}

.creative p {
    font-size: 16px;
    line-height: 1;
    text-align: center;
    color: #333;
    text-transform: capitalize;
}

.create-page .btn-upload-foto {
    position: fixed;
    bottom: 15px;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
}

.create-page .btn-upload-foto.disabled {
    background-color: #3b3c3e;
}

.collage-page {
    display: flex;
    flex-direction: column;
    height: 90%;
    // overflow: hidden;
    padding-bottom: 0;
    .btn-upload-foto {
        max-width: 200px;
    }

    .footer-links {
        display: none;
    }
}

.btns-container {
    position: relative;

    &-store {
        display: flex;
        justify-content: center;
        margin-bottom: 80px;
    }
}

.btn-store {
    font-weight: 700;
    font-size: 24px;
    line-height: 1;
    letter-spacing: 2.4px;
    color: #000;
    width: 320px;
    height: 80px;
    border-radius: 40px;
    background-color: #fff;
    text-transform: uppercase;
    margin: 0 24px;

    &:hover {
        opacity: .6;
    }
}

.btn-refresh-foto {
    font-size: 10px;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(110px, 5px);
    background-color: transparent;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    padding: 5px;
    svg {
        width: 24px;
    }
}

.collage-page .creative-holder {
    position: relative;
    max-width: 450px;
    width: 100%;
    margin: 0 auto 24px;

    .creative-holder-placeholder {
        padding-top: 100%;
    }

    .wait-video, video, img {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        z-index: 20;
    }

    .imageview-host {
        z-index: 20;
    }

    .wait-video {
        z-index: 5;
    }
}

.collage-page img,
.collage-page video {
    width: 100%;
    max-width: 450px;
    // max-height: 450px;
    height: 100%;
    display: block;
    margin: 0 auto;
}

.collage-page img {
    object-fit: contain;
    width: auto;
    max-width: 100%;
    max-height: 100%;
    border-radius: 24px;
    height: auto;
}
.collage-page video {
    object-fit: contain;
}

.collage-page .container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.collage-page-content {
    // flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.collage-page-text {
    margin-bottom: 27px;
}

/*loader*/
.loader {
    overflow: hidden;
    width: 100%;
    height: 100vh;
    position: relative;

    .loader-tip {
        font-size: 16px;
        font-weight: bold;
        line-height: 1.5;
        text-align: center;
        color: #01e777;
        margin-bottom: 16px;
    }
}

.collage-page-text {
    margin-bottom: 27px;
}

.loader-text-container {
    position: absolute;
    top: calc(40% + 220px);
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 500px;
    width: 80%;
}

.loader p {
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    color: #fff;
}

.spinner-container h3 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.avatar-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 120px;
    height: 120px;
    box-sizing: border-box;
    border-radius: 50%;
    object-fit: cover;
    object-position: top;
    animation-name: fadein;
    animation-duration: 320ms;
    animation-play-state: running;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
}

.spinner-container {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);

    h3 {
        font-size: 28px;
        line-height: 1;
        color: #95e09e;
        width: 140px;
        height: 140px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: solid 2px rgba(255, 255, 255, 0.25);
        border-radius: 50%;
    }

    svg {
        width: 120px;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.triple-spinner {
    display: block;
    position: relative;
    width: 240px;
    height: 240px;
    border-radius: 50%;
    border: solid 1px rgba(255, 255, 255, 0.15);
}

.triple-spinner2 {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border: solid 1px rgba(255, 255, 255, 0.15);
}

.triple-spinner::before,
.triple-spinner2::before  {
    content: "";
    position: absolute;
    border-radius: 50%;
    border: 1px solid transparent;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.triple-spinner::before {
    border: 1px solid #01e777;
    border-left-color: transparent;
    border-bottom-color: transparent;
    border-right-color: transparent;
    animation: spin-reverse 1.5s linear infinite;
}

.triple-spinner2::before {
    border: 1px solid #01e777;
    border-top-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
    animation: spin 3s linear infinite;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes spin-reverse {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(-360deg);
    }
}

/*modal*/
.-show-popup {
    overflow: hidden;
}

.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    backdrop-filter: blur(30px);
    background-color: rgba(1, 231, 119, 0.15);
    z-index: 30;
}

.modal {
    max-width: 640px;
    width: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    border-radius: 24px;
    box-shadow: 0 8px 60px 0 rgba(0, 0, 0, 0.6);
    background-color: #000;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px 50px;

    svg {
        position: absolute;
        top: 0;
        right: -2px;
        left: -2px;
        width: calc(100% + 4px);
        z-index: -5;
    }
    img {
        width: calc(100% + 32px);
        margin-left: -16px;
        margin-top: -40px;
    }
    p {
        font-size: 16px;
        line-height: 1.5;
        text-align: center;
        color: #fff;
        margin-bottom: 48px;

        span {
            font-weight: bold;
            color: #01e777;
        }
    }
    .btn-upload-foto {
        color: #fff;
        max-width: 100%;
        width: 100%;
    }
    h3 {
        font-size: 40px;
        font-weight: bold;
        line-height: 1.2;
        text-align: center;
        color: #fff;
        margin-bottom: 32px;
    }

    &_rate {
        padding: 76px 64px;

        p {
            font-size: 24px;
            margin-bottom: 64px;
        }

        h3 {
            span {
                color: #01e777;
            }
        }

        button {
            font-size: 20px;
            font-weight: bold;
            line-height: 1.2;
            letter-spacing: 2px;
            text-transform: uppercase;
            cursor: pointer;
            background: none;

            &:hover {
                opacity: .8;
            }
        }

        .rate-button {
            color: #000;
            width: 188px;
            height: 64px;
            border-radius: 40px;
            background-color: #01e777;
            margin-bottom: 20px;
        }

        .skip-button {
            color: #01e777;
            padding: 4px;
        }
    }

    @media all and (max-width: 780px) {
        padding: 24px;

        h3 {
            font-size: 24px;
            line-height: 1.5;
            margin-bottom: 16px;
        }

        p {
            font-size: 16px;
            margin-bottom: 24px;
        }

        &_rate {
            p {
                margin-bottom: 40px;
            }

            button {
                font-size: 16px;
                letter-spacing: 1.6px;
            }

            .rate-button {
                max-width: 240px;
                width: 100%;
                height: 48px;
                border-radius: 24px;
            }
        }
    }
}


.btn-share {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    svg {
        width: 25px;
        height: 26px;
        position: static;
        z-index: 10;
    }
}
.btn-share-snap {
    background-color: #ffdd0d;
}
.btn-share-fb {
    background-color: #1976d2;
}
.btn-share-insta {
    background-image: linear-gradient(45deg,#fd5 7%,#ff543e 50%,#c837ab 93%);
}

.btn-close {
    background-color: #95e09e;
    color: #1a3b3b;
    text-transform: uppercase;
    font-weight: 700;
    max-width: 240px;
    width: 90%;
    height: 48px;
    border-radius: 100px;
    flex-shrink: 0;
    width: 100px;
}

/*error-page*/
.error-page {
    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 94vh;
    }

    svg {
        height: 200px;
        margin-bottom: 48px;
    }
    
    h3 {
        font-weight: 700;
        margin-bottom: 16px;
    }

    .btn-upload-foto {
        color: #01e777;
        background-color: transparent;
        border: 1px solid #01e777;
        max-width: inherit;
        width: auto;
        position: relative;
        overflow: hidden;
        padding: 0 34px;
        margin: 48px auto 0;

        .effects {
            position: absolute;
            left: 0;
            top: 0;
            width: 50%;
            height: 100%;
            background-color: #01e777;
            color: #000000;
            border-radius: 100px;
            overflow: hidden;
            white-space: nowrap;
            display: flex;
            align-items: center;
            
            span {
                padding-left: 34px;
            }
        }
    }
}

.error-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 1.38;
    margin-bottom: 24px;

    p {
        text-align: center;
        line-height: 1.5;
    }
}

.btn-back {
    width: 40px;
    border: none;
    background: none;
    position: absolute;
    top: 44px;
    left: 80px;
    svg {
        width: 100%;
    }
}

.collage-page h2 {
    font-size: 16px;
    font-weight: 700;
    line-height: 1.38;
    text-align: center;
    color: #fff;
    width: 100%;
    margin-bottom: 16px;
    text-transform: uppercase;
}

.share-container {
    display: flex;
    align-items: center;
    overflow-x: auto;
    // position: absolute;
    // bottom: 0;
    // left: 0;
    // right: 0;
    position: relative;
    // left: -30px;
    // width: calc(100% + 60px);
    padding-top: 12px;
    padding-bottom: 12px;
    // border-top: 1px solid #d2d2d2;
    // border-bottom: 1px solid #d2d2d2;
    // margin-top: 10px;
}

.share-container button {
    width: 40px;
    height: 40px;
    border: none;
    background: no-repeat;
    flex-shrink: 0;
    margin-left: 24px;

    &:last-child {
        width: 1px;
    }
}

.share-container svg {
    width: 100%;
}

.steps-progress {
    position: fixed;
    height: 4px;
    width: 100%;
    background-color: #e7e7e7;
    top: 0;
    left: 0;
    z-index: 50;

    span {
        transition: 500ms;
        background-color: #feca28;
        display: block;
        height: 100%;
    }
}

.tabs-container {
    display: flex;
    justify-content: center;
    margin: 0 auto 14px;
    width: 100%;

    button {
        font-size: 16x;
        font-weight: 700;
        line-height: 1;
        letter-spacing: 2px;
        text-align: center;
        border-radius: 35px;
        border-bottom: 3px solid transparent;
        color: #fff;
        flex-shrink: 0;
        height: 48px;
        max-width: 185px;
        width: 45%;
        background: none;
        box-sizing: border-box;
        margin: 0 5px;

        svg, img {
            width: 64%;
        }
    }

    .active-tab-border {
        opacity: 0;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
    }

    .active-tab {
        .active-tab-border {
            opacity: 1;
        }
    }
}

.wait-video {
    overflow: hidden;
    max-width: 450px;
    margin: 0 auto;

    img {
        filter: blur(7px);
    }
}

.loader-video {
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 10;
}

.loader-video .item-loader {
    width: 9px;
    height: 9px;
    border-radius: 50%;
    animation: item-loader .9s linear infinite;
    margin: 0 6px;
}

.loader-video .first-item-loader {
    background-color: #1db8ff;
}

.loader-video .second-item-loader {
    background-color: #ffd300;
    animation: item-loader .9s linear infinite;
    animation-delay: .3s;
}

.loader-video .third-item-loader {
    background-color: #fc4700;
    animation: item-loader .9s linear infinite;
    animation-delay: .6s;
}


@keyframes item-loader {
    0% {
        transform: scale(1, 1);
    }

    50% {
        transform: scale(1.8, 1.8);
    }
}


.collage-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    .container {
        position: relative;
        display: block;
        height: 100%;
        width: 100%;
        flex-grow: 1;
        padding: 0;
    }
}

.collage-page {
    padding-top: 32px;
}

.collage {
    height: 100%;
    width: 100%;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    min-height: 0;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50%;
    max-width: 90%;
    display: flex;
    align-items: center;
    margin: 16px auto;
}

.tab-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.templates-container {
    flex-shrink: 0;
    max-width: 640px;
    margin: 0 auto;
    padding-top: 2px;
    padding-bottom: 2px;
    
    .container {
        display: flex;
        align-items: center;
        flex-direction: row;
        overflow: auto;
        width: 100%;
        max-width: 100%;
        padding-top: 2px;
        padding-left: 2px;
        padding-right: 2px;
        padding-bottom: 10px;

        &::-webkit-scrollbar-track {
            background-color: rgba(255, 255, 255, 0.15);
            height: 10px;
        }

        &::-webkit-scrollbar {
            padding: 10px 0;
            height: 2px;
            background-color: rgba(255, 255, 255, 0.15);
        }

        &::-webkit-scrollbar-thumb {
            background-color: #fff;
        }
    }

    button {
        flex-shrink: 0;
        overflow: auto;
        background-size: cover;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        font-size: 8px;
        text-align: center;
        color: #fff;
        position: relative;
        padding: 0 6px 4px;

        span {
            position: relative;
            z-index: 10;
            position: absolute;
            bottom: 4px;
            width: 86%;
        }

        &.named {
            &:after {
                content: "";
                display: block;
                width: 100%;
                height: 25px;
                background-image: linear-gradient(
                                180deg,transparent,#000);
                position: absolute;
                bottom: 0;
                left: 0;
            }
        }
    }
}

.btn-choice-template {
    // width: 96px;
    // height: 96px;
    margin-left: 8px;
    opacity: 0.75;
    width: 68px;
    height: 68px;
    border-radius: 14px;
    // border-radius: 24px;

    &.active {
        // border: 3px solid #fff;
        // opacity: 1;
        box-shadow: 0 0 0 3px #fff;
    }
}

.halloween-body-background img {
    display: block;
    width: 80%;
    height: auto;
    margin: auto;
}

.root > div:not(.loader-container) {
    display: flex;
    flex-direction: column;
}

/*loader*/
.loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    flex-direction: column;
    p {
        color: #fff;
        margin-bottom: 14px;
    }
}

.loader-android {
    width: 200px;
    height: 2px;
    background-color: rgba(5, 176, 255, 0.2);
    position: relative;
    overflow: hidden;
    span {
        position: absolute;
        top: 0;
        height: 100%;
        width: 70px;
        background-color: #05b0ff;
        animation: line-loader .9s linear infinite;
    }
    @keyframes line-loader {
        0% {
            left: -70px;
        }

        100% {
            left: calc(100% + 70px);
        }
    }
}

.loader-circle {
    display: flex;
    margin-bottom: 24px;

    &-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
  
    .item-loader {
        width: 9px;
        height: 9px;
        border-radius: 50%;
        animation: item-loader .9s linear infinite;
        background-color: #fff;
        opacity: 1;
        margin: 0 6px;
    }
    
    .second-item-loader {
        animation: item-loader .9s linear infinite;
        animation-delay: .3s;
    }
    
    .third-item-loader {
        animation: item-loader .9s linear infinite;
        animation-delay: .6s;
    }
}
  
  
@keyframes item-loader {
    0% {
          transform: scale(1, 1);
          opacity: 0;
    }
    80% {
          transform: scale(1.8, 1.8);
          opacity: 1;
    }
}

.btn-done {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    color: #1a3b3b;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 180px;
    height: 48px;
    border-radius: 40px;
    background-color: #95e09e;
    margin: 0 auto;
}

.footer-btns {
    position: relative;
    max-width: 510px;
    width: 100%;
    margin: 30px auto 0;

    &.invisible {
        visibility: hidden;
        pointer-events: none;
    }

}

.step {
    font-weight: 600;
    font-size: 16px;
    font-style: italic;
    color: #305f3d;
    line-height: 1;
    letter-spacing: 0.5px;
    text-align: center;
    width: 100%;
    margin-bottom: 8px;
}

.try-photo {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 8px;
    font-weight: 500;
    text-transform: uppercase;
    color: #95e09e;
    background: border-box;
    width: 58px;
    position: absolute;
    right: 5%;
    top: 0;

    &-container {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        border: solid 3px #95e09e;
        box-sizing: border-box;
        display: flex;
        margin-bottom: 5px;
    }

    img {
        width: 20px;
        border-radius: 0;
    }
    
}


.error-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 40px;
    box-sizing: border-box;

    p {
        line-height: 1.5;
    }

    button {
        height: 48px;
        border-radius: 100px;
        font-size: 16px;
        font-weight: bold;
        line-height: 1.38;
        letter-spacing: 0.8px;
        text-align: center;
        color: #01e777;
        text-transform: uppercase;
        border: solid 1px #01e777;
        background-color: transparent;
        padding: 0 54px;
        margin-top: 40px;

        @media all and (max-width: 780px) {
            font-size: 13px;
            height: 40px;
            letter-spacing: 1.3px;
        }
    }
}

.tab-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    flex-shrink: 0;
    overflow: auto;
    width: 100%;
    max-width: 730px;
    box-sizing: border-box;
    padding-top: 2px;
    padding-left: 2px;
    padding-right: 2px;
    padding-bottom: 10px;
    margin: 0 auto 32px;

    &::-webkit-scrollbar-track {
        background-color: rgba(255, 255, 255, 0.15);
        height: 10px;
        border-radius: 8px;
    }

    &::-webkit-scrollbar {
        padding: 10px 0;
        height: 4px;
        border-radius: 8px;
        background-color: rgba(255, 255, 255, 0.15);
    }

    &::-webkit-scrollbar-thumb {
        background-color: #fff;
        border-radius: 8px;
    }
}

.btn-choice-tab {
    width: 64px;
    height: 64px;
    border-radius: 16px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-shrink: 0;
    font-size: 8px;
    text-align: center;
    color: #fff;
    background-color: rgba(255, 255, 255, 0.6);
    background-size: cover; 
    padding: 0 6px 4px;
    margin-left: 8px;

    &.active {
        border: 4px solid #01e777;
    }

    &.waiting {
        .timer-loader {
            z-index: 10;
        }
    }

    &.error {
        opacity: .35;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 0;
        background-color: #212121;
        .error-icon {
            display: block;
            width: 24px;
            height: 24px;
            fill: #fff;
            opacity: .3;
        }
    }

    &.active.waiting {
        border: 0;
        box-shadow: inset 0 0 0 4px #01e777;

        .timer-loader {
            box-sizing: border-box;
        }
    }

    &:first-child {
        margin-left: 16px;
    }

    &:last-child {
        margin-right: 16px;
    }

    .error-icon {
        display: none;
    }
}

.btn-upload {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    color: #fff;
    letter-spacing: 0.8px;
    width: 48%;
    height: 48px;
    border-radius: 56px;
    border: solid 1px #fff;
    background: none;
    text-transform: uppercase;
}
.creative-download {
    font-weight: 700;
    font-size: 20px;
    text-transform: uppercase;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 188px;
    height: 64px;
    background-color: #01e777;
    border-radius: 56px;
}

.btns-container-creative {
    .creative-download {
        font-size: 16px;
        width: 48%;
        height: 48px;
    }
}

.result-page {
    // height: 94vh;
    height: $window-inner-height;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding-top: 64px;
    padding-bottom: 24px;

    h2 {
        font-weight: 700;
        font-size: 40px;
        line-height: 1.2;
        text-align: center;
        margin-bottom: 24px;
    }

    .result-content-title {
        font-size: 24px;
        line-height: 1.5;
        text-align: center;
        max-width: 400px;
        margin: 0 auto 64px;

        span {
            text-transform: uppercase;
            font-weight: bold;
        }
    }

    .slick-slider {
        position: relative;
        max-width: 720px;
        margin: 0 auto 80px;
    }

    .slick-dots {
        bottom: -46px;
    }

    .slick-dots li {
        width: 8px;
        height: 8px;
        margin: 0 6px;
    }

    .slick-dots button {
        opacity: 0.6;
        background-color: #fff;
        width: 8px;
        height: 8px;
        margin: 0;
    }
    .slick-dots .slick-active button {
        opacity: 1;
        background-color: #fff;
    }

    .creative-holder {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        box-sizing: border-box;
        padding: 24px 10px 0;
    }
}

.result-content {
    // flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .btns-container {
        display: flex;
        justify-content: center;
     }
}

.btn-get {
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 2px;
    text-transform: uppercase;
    height: 64px;
    padding: 0 56px;
    border-radius: 64px;
    background-color: #01e777;
}

.editor-page {
    display: flex;
    flex-direction: column;
    // height: 96vh;
    height: $window-inner-height;
    box-sizing: border-box;
    padding-top: 40px;
    padding-bottom: 40px;

    h2 {
        font-weight: 700;
        font-size: 40px;
        line-height: 1.2;
        text-align: center;
        margin-bottom: 16px;
    }

    p {
        opacity: 0.6;
        line-height: 1.5;
        text-align: center;
        color: #fff;
        margin-bottom: 24px;
    }
}

.creative-refresh {
    border: none;
    background: none;
    display: flex;
    align-items: center;

    span {
        font-weight: 700;
        font-size: 12px;
        line-height: 1.33;
        letter-spacing: 0.6px;
        color: #fff;
        text-transform: uppercase;
        margin-right: 24px;
    }

    img {
        position: relative;
        width: 32px;
        // border: solid 2px #000;
        border-radius: 50%;
        margin-left: -15px;

        &:nth-child(1) {
            z-index: 5;
        }
        &:nth-child(2) {
            z-index: 4;
        }
        &:nth-child(3) {
            z-index: 3;
        }
        &:nth-child(4) {
            z-index: 2;
        }
        &:nth-child(5) {
            z-index: 1;
        }
    }
}

.main-video-poster {
    z-index: -2;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    object-fit: cover;

    &.hidden {
        transition: opacity 1s;
        opacity: 0;
    }
}

.app-lang--ko,
.app-lang--ja {
    .mask_editor_screen .toolbox button {
        span {
            width: 250%;
        }
    }

    .mask_editor_screen .toolbox .tool_undo {
        margin-right: 48px;
    }
}

@media all and (max-width: 900px) {
    .main-page {
        padding: 48px 0 24px;
    }

    .logo {
        font-size: 16px;
        line-height: 1.5;
        text-align: center;
        margin-bottom: 16px;
    }

    .main-title {
        font-size: 24px;
        line-height: 1.6;
        background-color: #000;
        text-align: center;
        max-width: 100%;
        margin-bottom: 24px;
    }

    .main-subtitle {
        font-size: 16px;
        line-height: 1.5;
        text-align: center;
        background-color: #000;
        max-width: 100%;
        margin-bottom: 24px;
        margin-top: 24px;
    }

    .btn-upload-foto {
        font-size: 16px;
        letter-spacing: 1.6px;
        max-width: 240px;
        height: 64px;
        margin: 0 auto 32px;
    }

    .main-arrow-icon {
        display: none;
    }

    .rules-list-container {
        font-size: 16px;
        max-width: 100%;
        margin-bottom: 32px;
    }

    .rules-list-title {
        text-align: center;
        margin-bottom: 8px;
    }

    .rules-list-item {
        margin-bottom: 8px;

        &:before {
            top: 9px;
        }
    }

    .main-info-container {
        border-radius: 0;
        width: calc(100% + 30px);
        box-sizing: border-box;
        padding: 16px 24px;
        margin-left: -15px;
        margin-bottom: 40px;
    }

    .main-info-container .info-logo {
        display: none;
    }

    .main-info-content {
        width: 100%;
        .info-image {
            height: 48px;
            margin-right: 16px;
        }

        p {
            font-size: 16px;
            line-height: 1.5;
            text-align: center;
        }
    }

    .main-info-content-header {
        margin-bottom: 16px;
        p {
            font-size: 16px;
            line-height: 2;
        }
    }

    .btns-container-store {
        flex-direction: column;
        align-items: center;
        margin-bottom: 24px;
    }

    .btn-store {
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: 1.6px;
        width: 240px;
        height: 48px;
        margin: 0;
        margin-bottom: 16px;
    }

    .main-video-container {
        overflow: hidden;
        position: relative;
        z-index: -5;
        width: calc(100% + 30px);
        max-width: 450px;
        margin: 0 auto;
    }
}

.btns-container-creative {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 440px;
    margin: 0 auto;
}

.btns-container-refresh {
    display: flex;
    justify-content: flex-end;
    flex-shrink: 0;
    flex-grow: 1;
}

.creative-tabs {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: center;
    flex-shrink: 0;
    flex-grow: 1;
    overflow: auto;
    padding-bottom: 6px;

    &::-webkit-scrollbar-track {
        background-color: rgba(255, 255, 255, 0.15);
        height: 10px;
        border-radius: 8px;
    }

    &::-webkit-scrollbar {
        padding: 10px 0;
        height: 4px;
        border-radius: 8px;
        background-color: rgba(255, 255, 255, 0.15);
    }

    &::-webkit-scrollbar-thumb {
        background-color: #fff;
        border-radius: 8px;
    }

    button {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        flex-shrink: 0;
        background-size: cover;
        position: relative;
        // border: solid 3px #01e777;
        background-color: #01e777;
        margin-left: 16px;

        &.active {
            border: solid 3px #01e777;
            height: 48px;
            width: 48px;
        }

        .timer-loader {
            z-index: 10;
        }

        svg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 10;
        }
    }

    &.-hide {
        visibility: hidden;
    }

    &.-remove {
        display: none;
    }
}

.timer-loader {
    width: 48px;
    height: 48px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -10;
    margin: 0;

    .rotate {
        width: 100%;
        height: 100%;
        display: block;
        position: relative;
        z-index: 10;
    }

    .rotate path {
        fill: rgba(0, 0, 0, 0.3);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(50%, 50%);
    }
}

.loader-roller-wrapper {
    width: 80px;
    height: 50px;
    position: relative;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%,-50%);
    z-index: 20;
    margin: 0 auto 24px;
}

.loader-roller {
    width: 80px;
    height: 50px;
    top: 0;
    left: 0;
    position: absolute;

    &:after {
        content: "";
        top: auto;
        position: absolute;
        display: block;
        animation: shadow 1.2s linear infinite;
        bottom: 0;
        left: 0;
        height: 4px;
        width: 12px;
        border-radius: 50%;
        background-color: #000;
    }

    .roller {
        width: 40px;
        height: 40px;
        position: absolute;
        top: 0;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        animation-duration: 1.2s;
    
        &:first-child {
            left: 0;
            animation-name: rollercoaster;
            transform: rotate(135deg);
        }

        &:last-child {
            right: 0;
            animation-name: rollercoaster2;
            transform: rotate(-45deg);
        }

        &:before {
            content: "";
            display: block;
            width: 12px;
            height: 12px;
            background-color: #a4e6a7;
            border-radius: 50%;
        }
    }
}

.loader-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }

    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 100%;
        height: 100%;
        backdrop-filter: blur(8px);
        background-color: rgba(0, 0, 0, 0.3);
        z-index: 10;
    }
}

.loader-text {
    position: relative;

    p {
        font-size: 16px;
        position: static;
        transform: none;
        text-align: center;
        margin: 0 auto 24px;
    }

    svg {
        position: absolute;
        top: 30px;
        width: calc(100% + 40px);
        left: 50%;
        transform: translateX(-50%);
    }

    li {
        font-size: 14px;
        font-style: italic;
        margin-bottom: 12px;
        border-radius: 16px;
        background-color: rgba(255, 255, 255, 0.05);
        max-width: 320px;
        height: 52px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        line-height: 1.42;
        padding: 0 24px;
        box-sizing: border-box;
    }
}

.loader-roller2 .roller,
.loader-roller2:after {
    animation-delay: .15s
}

.loader-roller3 .roller,
.loader-roller3:after {
    animation-delay: .3s
}

@keyframes rollercoaster {
    0% {
        transform: rotate(135deg)
    }

    8% {
        transform: rotate(240deg)
    }

    20% {
        transform: rotate(300deg)
    }

    40% {
        transform: rotate(380deg)
    }

    45% {
        transform: rotate(440deg)
    }

    50% {
        transform: rotate(495deg);
        opacity: 1
    }

    50.1% {
        transform: rotate(495deg);
        opacity: 0
    }

    to {
        transform: rotate(495deg);
        opacity: 0
    }
}

@keyframes rollercoaster2 {
    0% {
        opacity: 0
    }

    49.9% {
        opacity: 0
    }

    50% {
        opacity: 1;
        transform: rotate(-45deg)
    }

    58% {
        transform: rotate(-160deg)
    }

    70% {
        transform: rotate(-240deg)
    }

    80% {
        transform: rotate(-300deg)
    }

    90% {
        transform: rotate(-340deg)
    }

    to {
        transform: rotate(-405deg)
    }
}

@keyframes shadow {
    0% {
        opacity: .3;
        transform: translateX(35px) scale(.25)
    }

    8% {
        transform: translateX(9px) scale(1)
    }

    20% {
        transform: translateX(0) scale(.6)
    }

    40% {
        transform: translateX(-5px) scale(.25);
        opacity: .1
    }

    50% {
        transform: translateX(32px) scale(.6);
        opacity: .3
    }

    60% {
        transform: translateX(70px) scale(1);
        opacity: .05
    }

    65% {
        transform: translateX(78px) scale(.6)
    }

    80% {
        transform: translateX(65px) scale(.25);
        opacity: .1
    }

    90% {
        transform: translateX(43px) scale(.4)
    }

    to {
        transform: translateX(32px);
        opacity: .3
    }
}

.text-modal {
    position: absolute  ;
    width: 194px;
    bottom: 8px;
    right: -20px;
    border-radius: 12px;
    background-color: rgba(0, 0, 0, 0.8);
    padding: 8px;

    p {
        font-size: 13px;
        color: #fff;
        line-height: 1.38;
        position: static;
        transform: none;
        text-align: left;
        letter-spacing: 0.5px;

        &:first-child {
            font-weight: bold;
            text-transform: uppercase;
        }
    }

    .btn-close-tooltip {
        position: absolute;
        top: 2px;
        right: 2px;
        padding: 4px;
        width: 16px;
        height: 16px;
        background: none;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            width: 100%;
        }
    }
}

.btn-choose-text {
    font-weight: 700;
    font-size: 12px;
    color: #01e777;
    text-transform: uppercase;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -16px;
    right: -10px;
    background: none;
    padding: 0 10px 10px;

    img {
        width: 12px;
        margin-right: 6px;
    }
}

.error-container {
    position: absolute;
}

.creative-error-message {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
        position: static;
        transform: none;
    }
}

.webview {
    .logo,
    .rules-list-container,
    .main-info-container,
    .btns-container-store,
    .footer-links {
        display: none;
    }

    .btn-upload-foto {
        margin-bottom: 0;
    }

    // .main-page {
    //     min-height: auto;
    // }
}

.btn-back-npp {
    font-size: 20px;
    text-transform: uppercase;
    font-weight: bold;
    // color: #000;
    color: #00ba7b;
    // display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 1.3px;
    display: inline-block;
    // width: 320px;
    border-radius: 24px;
    // background-color: #01e777;
    // position: fixed;
    // bottom: 16px;
    // left: 50%;
    // transform: translateX(-50%);
    // padding: 13px 0 11px;
    z-index: 20;
    text-decoration: underline;
    margin-top: 80px;

    span {
        font-weight: bold;
    }

    @media all and (max-width: 950px) {
        font-size: 16px;
        display: flex;
        margin: 40px auto 0;
    }
}

.safe-page {
    font-size: 24px;
    line-height: 1.67;
    text-align: left;
    color: #000;
    background-color: #e5e5e5;
    padding-bottom: 80px;

    header {
        // font-size: 40px;
        // font-weight: bold;
        // line-height: 1.2;
        // color: #000;
        // padding-top: 64px;
        margin-bottom: 40px;

        // span {
        //     color: #00ba7b;
        // }

        position: relative;
        margin-top: 64px;
        margin-bottom: 116px;

        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .container {
        max-width: 1112px;
        padding: 0 16px;
    }

    .section-1 {
        position: relative;
        margin-bottom: 80px;
        
        h1 {
            font-size: 64px;
            font-weight: bold;
            line-height: 1.25;
            position: relative;
            z-index: 10;
            margin-bottom: 40px;
        }

        p {
            font-size: 32px;
            line-height: 1.5;

            span {
                font-weight: bold;
            }

            a {
                font-weight: bold;
                color: #00ba7b;
                text-decoration: underline;
            }
        }

        img {
            max-width: 560px;
            flex-shrink: 0;
            position: absolute;
            right: -160px;
            top: -106px;
            z-index: 0;
        }
    }

    .section-1-text {
        max-width: 750px;

        p {
            position: relative;
            z-index: 10;
            max-width: 670px;
        }
    }

    .section-2 {
        font-size: 32px;
        font-weight: bold;
        line-height: 1.5;
        text-transform: uppercase;
        position: relative;
        margin-bottom: 80px;

        a {
            color: #ee8834;
            text-decoration: underline;
        }
    }

    .section-3 {
        p {
            font-size: 24px;
            line-height: 1.67;
            margin-bottom: 40px;

            span {
                font-style: italic;
            }

            &:last-child {
                margin-bottom: 0;
            }

            a {
                font-weight: bold;
                color: #00ba7b;
                text-decoration: underline;
            }
        }

        .mb-0 {
            margin-bottom: 0;
        }
    }

    .pl-200 {
        padding-left: 200px;
    }

    .pl-140 {
        padding-left: 140px;
    }

    .pl-160 {
        padding-left: 160px;
    }

    .pr-160 {
        padding-right: 160px;
    }

    .social-block {
        margin-top: 80px;

        h3 {
            font-size: 20px;
            font-weight: bold;
            line-height: 1.2;
            color: #bbb;
            text-transform: uppercase;
            margin-bottom: 24px;
        }

        p {
            font-size: 24px;
            line-height: 1.67;

            a {
                font-weight: bold;
                text-decoration-line: underline;
                background: linear-gradient(89.67deg, #FF9842 35.68%, #E4308E 59.04%, #A53CAC 99.76%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
            }
        }

        ul {
            display: flex;
            margin-bottom: 40px;
        }

        li {
            display: flex;
            margin-right: 40px;
        }

        button {
            width: 48px;
            height: 48px;
            background: none;

            svg {
                width: 100%;
                height: 100%;
            }
        }
    }

    .section-4 {
        margin-top: 160px;
        margin-bottom: 160px;

        h2 {
            font-size: 40px;
            font-weight: bold;
            line-height: 1.4;
            max-width: 560px;
            margin-bottom: 40px;
        }

        .section-4-block {
            display: flex;
            align-items: center;
        }

        .section-4-block-text {
            font-size: 24px;
            line-height: 1.67;
            border-radius: 6px;
            background-image: linear-gradient(to right, rgba(229, 229, 229, 0)  0%, rgba(0, 0, 0, 0.1) 100%);
            max-width: 882px;
            flex-grow: 1;
            box-sizing: border-box;
            position: relative;
            padding-right: 76px;

            .bold {
                font-weight: 600;
            }

            &::after {
                content: '';
                display: block;
                position: absolute;
                right: 0;
                top: 0;
                height: 100%;
                width: 6px;
                border-radius: 6px;
                background-color: #00ba7b;
            }

            a {
                font-weight: 500;
                color: #00ba7b;
                text-decoration: underline;
            }
        }

        .section-4-block-text2 {
            background: none;
            right: 2px;
            padding-top: 40px;
            padding-bottom: 40px;

            &-empty {
                padding-top: 0;
            }

            &::after {
                width: 2px;
                background-color: rgba(0, 0, 0, 0.1);
            }
        }

        .section-4-block-date {
            font-size: 48px;
            line-height: 1.67;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 200px;
            flex-shrink: 0;
        }
    }

    .section-5 {
        margin-bottom: 80px;
        h2 {
            font-size: 40px;
            font-weight: bold;
            line-height: 1.4;
            margin-bottom: 40px;
        }

        p {
            font-size: 24px;
            line-height: 1.67;
            margin-bottom: 40px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .section-6 {
        p {
            font-size: 24px;
            font-weight: bold;
            line-height: 1.67;
        }
    }

    .section-7 {
        p {
            font-size: 32px;
            font-weight: bold;
            line-height: 1.5;
        }

        span {
            font-style: italic;
        }
    }

    .section-8 {
        position: relative;
        max-width: 800px;
        margin-top: 160px;
        margin-bottom: 40px;

        h2 {
            font-size: 40px;
            font-weight: bold;
            line-height: 1.4;
            margin-bottom: 40px;
        }

        p {
            font-size: 24px;
            line-height: 1.67;
            margin-bottom: 40px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        a {
            text-decoration: underline;
        }

        .bold {
            font-weight: bold;
        }

        .red {
            color: #ee8834;
        }

        .green {
            color: #00ba7b;
        }

        img {
            position: absolute;
            left: 100%;
            top: 0;
            max-width: 420px;
        }
    }

    .section-9 {
        margin-top: 160px;

        h2 {
            font-size: 40px;
            font-weight: bold;
            line-height: 1.4;
            margin-bottom: 40px;

            span {
                font-style: italic;
            }
        }

        h3 {
            font-size: 24px;
            font-weight: bold;
            line-height: 1.2;
            text-transform: uppercase;
            padding-bottom: 10px;
            border-bottom: 2px solid #000;
        }

        .yellow {
            color: #ee8834;
        }

        .green {
            color: #00ba7b;
        }

        .section-9-compare-block {
            display: flex;
        }

        .section-9-compare {
            flex: 1 1;
            max-width: 50%;
        }

        .section-9-compare-content {
            &-green {
                background-color: #00ba7b;
                border-bottom-left-radius: 16px;
                border-bottom-right-radius: 16px;
                padding-left: 32px;
            }
        }

        .section-9-compare-logos {
            display: flex;
            align-items: center;
            column-gap: 20px;
            margin-bottom: 24px;
            margin-right: 40px;
            padding: 24px 0 22px;

            &-border {
                border-bottom: 2px solid rgba(0, 0, 0, 0.1);
            }
        }

        li {
            position: relative;
            padding-left: 28px;
            padding-right: 32px;
            margin-bottom: 24px;

            span {
                font-weight: 600;
                font-style: italic;
            }

            &:last-child {
                margin-bottom: 0;
            }

            &::after {
                content: '';
                position: absolute;
                top: 14px;
                left: 0;
                width: 12px;
                height: 12px;
                display: block;
                background-size: contain;
            }
        }

        .error-list {
            color: #666;

            li {
                &::after {
                    background-image: url("/assets/images/article/error.png");
                }
            }
        }

        .check-list {
            li {
                &::after {
                    background-image: url("/assets/images/article/check.png");
                }
            }
        }

        .h32 {
            height: 32px;

            @media all and (max-width: 1100px) {
                height: 20px;
            }
        }

        .h40 {
            height: 40px;

            @media all and (max-width: 1100px) {
                height: 28px;
            }
        }

        .h56 {
            height: 56px;

            @media all and (max-width: 1100px) {
                height: 40px;
            }
        }

        .h64 {
            height: 64px;

            @media all and (max-width: 1100px) {
                height: 40px;
            }
        }

        .mt56 {
            margin-top: 56px;
        }
    }

    .mob {
        display: none;
    }

    @media all and (max-width: 1280px) {
        .section-1 img {
            max-width: 500px;
            right: -70px;
            top: -82px;
        }
    }

    @media all and (max-width: 1000px) {
        header {
            font-size: 16px;
            line-height: 1.5;
            padding-top: 32px;
            margin-bottom: 16px;
            display: flex;
            justify-content: center;
        }

        .desktop {
            display: none;
        }

        .mob {
            display: block;
        }

        .section-1 {
            margin-bottom: 24px;
        }

        .section-1-text,
        .section-1-text p {
            max-width: 100%;
        }

        .section-1 h1 {
            font-size: 24px;
            line-height: 1.67;
            text-align: center;
            order: 1;
            margin-bottom: 0;
        }

        .section-1-text {
            display: flex;
            flex-direction: column;
        }

        .section-1-text p {
            font-size: 16px;
            line-height: 1.5;
            text-align: center;
            order: 3;
        }

        .section-1 img {
            position: static;
            order: 2;
            width: 100%;
            margin: 0 auto 24px;
        }

        .section-2 {
            font-size: 16px;
            line-height: 1.5;
            text-align: center;
            margin-bottom: 80px;

            br {
                display: none;
            }
        }

        .section-3 p {
            font-size: 16px;
            line-height: 1.5;
            text-align: center;
            margin-bottom: 24px;
        }

        .section-8 {
            max-width: 100%;
            margin-top: 120px;
            margin-bottom: 16px;
        }

        .section-8 h2 {
            font-size: 24px;
            line-height: 1.67;
        }

        .section-8 p {
            font-size: 16px;
            line-height: 1.5;
            margin-bottom: 24px;
        }

        .section-8 img {
            display: none;
        }

        .section-7 p {
            font-size: 16px;
            line-height: 1.5;
        }

        .section-4 {
            margin-top: 120px;
            margin-bottom: 120px;

            h2 {
                font-size: 24px;
                line-height: 1.67;
                max-width: 100%;
                text-align: center;
                margin-bottom: 32px;
            }

            .section-4-block {
                flex-direction: column-reverse;
                align-items: flex-end;
            }

            .section-4-block-text {
                width: calc(100% + 15px);
                position: relative;
                right: -15px;
                font-size: 16px;
                line-height: 1.5;
                padding-right: 16px;
            }

            .section-4-block-date {
                width: auto;
                font-size: 24px;
                line-height: 1.67;
            }

            .section-4-block-text2 {
                &:after {
                    content: none;
                }
            }

            .section-4-block-text2-empty {
                padding-bottom: 32px;
            }
        }

        .section-5 h2 {
            font-size: 24px;
            line-height: 1.67;
            text-align: center;
            margin-bottom: 24px;
        }

        .section-5 p {
            font-size: 16px;
            line-height: 1.5;
            margin-bottom: 24px;
        }

        .section-6 p {
            font-size: 16px;
            line-height: 1.5;
            text-align: center;
        }

        .social-block {
            display: flex;
            flex-direction: column;
            align-items: center;

            h3 {
                text-align: center;
                font-size: 16px;
            }
        }

        .social-block li {
            margin: 0 20px;
        }

        .social-block p {
            font-size: 20px;
            line-height: 1.33;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
        }

        .pl-200,
        .pl-160,
        .pr-160 {
            padding: 0;
        }

        .section-9 {
            margin-top: 120px;
    
            h2 {
                font-size: 24px;
                line-height: 1.67;
                text-align: center;
                margin-bottom: 24px;
            }
    
            h3 {
                font-size: 16px;
                line-height: 1.5;
                padding: 0;
                border: none;
            }
    
            .section-9-compare-block {
                flex-direction: column;
            }
    
            .section-9-compare {
                max-width: 100%;
            }
    
            .section-9-compare-logos {
                column-gap: 16px;
                margin-right: 0;
                padding-top: 16px;
            }
    
            li {
                font-size: 16px;
                line-height: 1.5;
                padding-left: 24px;
                margin-bottom: 16px;
    
                &::after {
                    top: 8px;
                    width: 8px;
                    height: 8px;
                }
            }
    
            .section-9-compare-green {
                width: calc(100% + 16px);
                padding-left: 16px;
                margin-left: -16px;
                max-width: initial;
                background-color: #00ba7b;
                margin-top: 30px;
                border-top: 2px solid #000;
                border-bottom-left-radius: 32px;
                border-bottom-right-radius: 32px;
                overflow: hidden;
            }
    
            .section-9-compare-content-green {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
                padding-left: 0;
                padding-bottom: 32px;
    
                .section-9-compare-logos {
                    padding-bottom: 0;
                }
            }
    
            .green {
                color: #fff;
                margin-top: 32px;
                margin-bottom: 16px;
            }
    
            .mt56 {
                margin-top: 40px;
            }
        }
    }

    @media all and (max-width: 375px) {
        .section-9 {
            .h32 {
                height: 16px;
            }
            .h40 {
                height: 24px;
            }
        }
    }
}

.lang-change {
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 24px;
    border: solid 1px #cbcbcb;
    overflow: hidden;
    z-index: 20;

    ul {
        position: relative;
        z-index: 10;
        padding-bottom: 12px;
        padding-top: 16px;

        button {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 0 12px;
        }

        .lang-change-flag {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
            flex-shrink: 0;
            margin-right: 12px;
        }

        .lang-change-name {
            opacity: 0.3;
            font-size: 16px;
            font-weight: normal;
            line-height: 1.5;
            color: #000;
        }
    }

    li {
        margin-bottom: 16px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    button {
        background: none;
    }

    &-open {
        .lang-change-icon {
            transform: rotate(180deg);
        }
    }

    &::after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.1);
        background-color: #e5e5e5;
        position: absolute;
        top: 0;
        left: 0;
        box-sizing: border-box;
    }
}

.lang-change-main-btn {
    width: 124px;
    height: 48px;
    padding: 8px;
    // border-radius: 48px;
    box-sizing: border-box;
    // box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.1);
    // border: solid 1px #cbcbcb;
    // background-color: #e5e5e5;
    display: flex;
    align-items: center;
    background: none;
    position: relative;
    z-index: 10;
}

.lang-change-flag {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    overflow: hidden;
    flex-shrink: 0;
    margin-right: 8px;

    img {
        width: 100%;
        height: 100%;
    }
}

.lang-change-name {
    font-size: 24px;
    font-weight: bold;
    line-height: 1;
    text-transform: uppercase;
    margin-right: 12px;
}

.lang-change-icon {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
}

@media all and (max-width: 1000px) {
    .safe-page header {
        margin-top: 16px;
    }
    .lang-change {
        border-radius: 48px;

        &-open {
            border-radius: 24px;
        }

        ul {
            padding-top: 0;

            button {
                padding: 0 16px;
            }

            .lang-change-flag {
                margin-right: 8px;
            }
        }
    }

    .lang-change-main-btn {
        width: 120px;
        height: 56px;
        padding: 0 16px;
    }

    .lang-change-flag {
        width: 24px;
        height: 24px;
    }

    .lang-change-name {
        font-size: 16px;
        line-height: 1.5;
        margin-right: 18px;
    }

    .lang-change-icon {
        width: 16px;
        height: 16px;
    }
}

.creative .watermark-image {
    position: absolute;
    right: 0px;
    bottom: 0px;
    //width: 153px;
    //height: auto;
    border-radius: 0;
}

.app-lang--ru {
    .main-title {
        font-size: 64px;

        @media all and (max-width: 900px) {
            font-size: 24px;
        }
    }

    .main-subtitle {
        font-size: 36px;

        @media all and (max-width: 900px) {
            font-size: 16px;
        }
    }

    .mask_editor_screen .toolbox button span {
        width: auto;
    }

    .mask_editor_screen .toolbox .buttons-container-tools .tool-save {
        width: auto;
        padding: 0 10px;
    }
}

@media all and (max-height: 1050px) and (orientation: landscape) {
    .tab-container {
        max-width: 550px;
    }
    .creative-holder,
    .btns-container-creative,
    .creative-container {
        max-width: 514px;
    }
}

@media all and (max-height: 960px) and (orientation: landscape) {
    .main-title {
        font-size: 70px;
    }
}

@media all and (max-height: 870px) and (orientation: landscape) {
    .result-page {
        h2 {
            font-size: 24px;
            margin-bottom: 16px;
        }
        .slick-dots {
            bottom: -32px;
        }

        .btn-back {
            width: 24px;
            left: 16px;
            top: 26px;
        }
    }

    .editor-page  {
        padding-top: 16px;
        padding-bottom: 16px;
        h2 {
            font-size: 24px;
            margin-bottom: 0;
            margin-right: 40px;
        }

        .btn-back {
            width: 24px;
            left: 16px;
            top: 16px;
        }

        p {
            margin-bottom: 0;

            &:before {
                content: '(';
            }

            &:after {
                content: ')';
            }
        }
    }

    .editor-page-container {
        display: flex;
        align-items: baseline;
        justify-content: center;
        margin-bottom: 16px;
    }

    .mask_editor_screen {
        .canvas_holder {
            margin-bottom: 24px;
        }
        .controlbox {
            margin-bottom: 34px;

            .control_name, 
            .control_value {
                font-size: 20px;
                top: 12px;
            }
        }
        .toolbox {
            margin-bottom: 22px;
            button {
                margin-right: 32px;
                svg {
                    height: 32px;
                }
            }
        }

        .tool-save {
            font-size: 18px;
            height: 48px;
        }
    }

    .app-lang--ru {
        .mask_editor_screen .toolbox .tool {
            margin-right: 48px;

            &:last-child {
                margin-right: 0;
            }
        }

        .mask_editor_screen .toolbox button span {
            bottom: -20px;
            font-size: 14px;
        }
    }
}

@media all and (max-height: 800px) and (orientation: landscape) {
    .main-title {
        font-size: 70px;
        margin-bottom: 24px;
    }

    .main-subtitle {
        font-size: 36px;
        margin-bottom: 24px;
    }

    .main-video-container {
        width: 480px;
    }

    .logo {
        margin-bottom: 10px;
    }
}

@media all and (max-height: 780px) and (orientation: landscape) {
    .main-title {
        font-size: 58px;
    }

    .main-subtitle {
        font-size: 30px;
    }
}

@media all and (max-height: 730px) and (orientation: landscape) {
    .loader-roller-wrapper {
        margin-bottom: 4px;
    }

    .loader-text svg {
        width: 77%;
    }

    .loader-text p {
        margin-bottom: 12px;
    }

    .loader-text li {
        font-size: 12px;
        max-width: 380px;
        height: 40px;
        margin-bottom: 8px;
    }
}

@media all and (max-height: 680px) and (orientation: landscape) {
    .result-page .result-content-title {
        margin-bottom: 24px;
    }

    .result-page .result-content-title {
        font-size: 16px;
    }

    .result-page .slick-slider {
        margin-bottom: 24px;
    }

    .slide {
        width: 180px;
        height: 180px;
    }

    .btn-get {
        font-size: 13px;
        letter-spacing: 1.3px;
        height: 40px;
    }
}

@media all and (max-height: 640px) and (orientation: landscape) {
    .main-title {
        font-size: 54px;
        margin-bottom: 20px;
    }

    .main-subtitle {
        font-size: 28px;
        margin-bottom: 30px;
    }

    .main-page {
        padding-top: 68px;
    }

    .tab-container {
        margin-bottom: 16px;
    }

    .creative-container {
        margin-bottom: 16px;
    }

    .result-page {
        padding-top: 24px;
    }
}

@media all and (max-width: 780px) {
    .app-lang--ru {
        .error-container button {
            padding: 0 20px;
        }
    }

    .btns-container-creative {
        box-sizing: border-box;
        padding: 0 15px;
    }

    .creative-download {
        font-size: 16px;
        width: 240px;
        height: 48px;
        margin-bottom: 16px;
    }

    .btns-container-creative {
        .creative-download {
            font-size: 13px;
            letter-spacing: 1.3px;
            height: 40px;
            margin: 0;
        }
    }

    .btn-upload {
        font-size: 13px;
        letter-spacing: 1.3px;
        height: 40px;
    }

    .btn-back {
        left: 60px;
    }

    .editor-page  {
        p {
            display: none;
        }
    }

    .btn-choose-text {
        font-size: 8px;
        img {
            width: 8px;
            margin-right: 4px;
        }
    }

    .error-page .btn-upload-foto {
        height: 40px;
        letter-spacing: 1.3px;
        font-size: 13px;
        padding: 0 12px;

        .effects {
            span {
                padding-left: 12px;
            }
        }
    }

    .error-content {
        position: relative;
        padding-top: 248px;

        svg {
            position: absolute;
            top: 0;
            left: -30px;
        }
    }

}

@media all and (max-width: 720px) {
    .creative-container {
        box-sizing: border-box;
        padding: 0 16px;
    }

    .result-page .slick-slider {
        max-width: 100%;
    }
}

@media all and (max-width: 560px) {
    .creative-holder {
        // flex-grow: 0;

        // &:after {
        //     padding-top: 100%;
        // }
    }

    .btn-change-mask {
        box-sizing: border-box;
        padding: 4px;
    }

    .creative-tabs button {
        width: 32px;
        height: 32px;

        &.active {
            width: 46px;
            height: 46px;   
        }
    }

    .main-page {

        .container {
            height: 100%;
        }

        .main-video-container {
            flex-grow: 1;
        }
    }
    
    .main-info-content-header {
        flex-direction: column;
    }
    .result-page {
        // height: 92vh;
        padding-top: 32px;
        padding-bottom: 16px;

        h2 {
            font-size: 24px;
            line-height: 1;
        }
    }

    .result-page .slick-slider {
        max-width: 100%;
    }

    .creative-download {
        font-size: 16px;
        height: 48px;
    }

    .slick-dots li {
        width: 6px;
        height: 6px;
        margin: 0 4px;
    }

    .slick-dots button {
        width: 6px;
        height: 6px;
    }

    .ios {
        .editor-page,
        .result-page {
            // padding-bottom: 0;
            // height: 82vh;
            height: $window-inner-height;
        }

        &.webview {
            .main-page {
                // height: 88vh;
                height: $window-inner-height;
                padding-bottom: 16px;
            }

            .editor-page {
                padding-bottom: 0;
                // height: 92vh;
                height: $window-inner-height;
            }

            .result-page {
                // height: 92vh;
                height: $window-inner-height;
                padding-bottom: 16px; 
            }
        }
    }

    .webview {
        .main-page {
            // height: 94vh;
            height: $window-inner-height;
        }
        .editor-page {
            // height: 98vh;
            height: $window-inner-height;
            padding-bottom: 0;
        }

        .result-page {
            // height: 96vh;
            height: $window-inner-height;
        }
    }

    .editor-page  {
        padding-top: 24px;
        padding-bottom: 24px;

        h2 {
            font-size: 24px;
            line-height: 1;
        }
    }

    .btn-back {
        width: 24px;
        left: 16px;
        top: 24px;

    }

    .loader-text p {
        font-size: 14px;
        margin-bottom: 12px;
    }

    .loader-text li {
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 8px;
    }
}

@media all and (max-height: 830px) and (orientation: portrait) {
    .result-page {
        padding-top: 32px;
        h2 {
            font-size: 24px;
            line-height: 1;
            margin-bottom: 16px;
        }
    }
    .editor-page  {
        h2 {
            font-size: 24px;
            line-height: 1;
            margin-bottom: 32px;
        }
    }

    .btn-choice-tab {
        width: 56px;
        height: 56px;
        border-radius: 16px;
    }

    .timer-loader {
        width: 40px;
        height: 40px;
    }
}

@media all and (max-width: 480px) {
    .main-title {
        width: calc(100% + 30px);
        max-width: initial;
        background-color: #000;
        box-sizing: border-box;
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 24px;
        margin: 0;
        margin-left: -15px;
    }

    .main-subtitle {
        width: calc(100% + 30px);
        max-width: initial;
        background-color: #000;
        box-sizing: border-box;
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 24px;
        margin: -50px -15px 24px;
    }

    .main-video-container {
        max-width: 480px;
        margin-top: -50px;
        margin-left: -15px;
    }

    .text-modal {
        right: 16px;
    }
}

@media all and (max-width: 440px) {
    .btn-choose-text {
        right: 0;
    }

    .btns-container-refresh {
        padding-right: 0;
        box-sizing: border-box;
    }

    .result-page .result-content-title {
        font-size: 16px;
        line-height: 1.5;
        max-width: 280px;
        margin-bottom: 48px;
    }

    .result-page .slick-slider {
        margin-bottom: 48px;
    }

    .btn-get {
        font-size: 13px;
        letter-spacing: 1.3px;
        height: 40px;
        padding: 0 30px;
    }

    .loader-text svg {
        top: 28px;
        width: 100%;
    }

    .safe-and-cool svg {
        width: 16px;
        margin-left: 8px;
    }
}

@media all and (max-width: 410px) {
    .loader-text p {
        font-size: 14px;
        margin-bottom: 10px;
    }

    .loader-text li {
        font-size: 12px;
        margin-bottom: 6px;
    }
}

@media all and (max-width: 390px) {
    .tab-container {
        margin-bottom: 32px;
    }

    .app-lang--ru {
        .mask_editor_screen .toolbox button {
            margin-right: 20px;
        }

        .safe-and-cool a {
            font-size: 12px;
        }
    }

    .result-page .creative-holder {
        padding: 0 10px;
    }
}

@media all and (max-width: 360px) {
    .loader-text p {
        font-size: 13px;
        margin-bottom: 10px;
    }

    .loader-text li {
        font-size: 11px;
        margin-bottom: 6px;
    }

    .safe-and-cool a {
        font-size: 14px;
    }
}

@media all and (max-height: 800px) and (orientation: portrait) {
    .editor-page  {
        h2 {
            margin-bottom: 16px;
        }
    }

    .tab-container {
        margin-bottom: 32px;
    }
}

@media all and (max-height: 780px) and (orientation: portrait) {
    .main-title {
        line-height: 1.5;
        padding-bottom: 10px;
    }

    .logo {
        margin-bottom: 10px;
    }

    .main-video-container {
        max-width: 370px;
        width: 100%;
        margin: -40px auto;
    }

    .main-subtitle {
        padding-top: 16px;
        padding-bottom: 16px;
        margin: 0px -15px;
    }
}

@media all and (max-height: 700px) and (orientation: portrait) {
    .main-video-container {
        max-width: 320px;
    }
}

@media all and (max-height: 680px) and (orientation: portrait) {
    .main-video-container {
        max-width: 270px;
    }
}

@media all and (max-height: 630px) and (orientation: portrait) {
    .result-page .creative-holder {
        padding-top: 16px;
    }

    .loader-roller-wrapper {
        margin-bottom: 8px;
    }

    .loader-text li {
        height: 40px;
    }

    .loader-text svg {
        top: 24px;
        width: 85%;
    }

    .app-lang--ru .main-title,
    .main-title {
        font-size: 18px;
    }
}

@media all and (max-height: 540px) and (orientation: portrait) {
    .slide {
        width: 160px;
        height: 160px;
    }

    .result-page .result-content-title,
    .result-page .slick-slider {
        margin-bottom: 32px;
    }

    .error-content {
        padding-top: 208px;
    }

    .error-content svg {
        height: 160px;
        margin-bottom: 24px;
    }

    .error-page .btn-upload-foto {
        margin-top: 16px;
    }
}
