// html, body, .root, main {
//     height: 100%;
// }
// .root > div {
//     height: 100%;
//     & > div {
//         height: 100%;
//     }
// }

//@font-face {
//    font-family: 'DM';
//    font-weight: normal;
//    src: url('%REACT_APP_ASSETS_PATH%/assets/fonts/DM_Sans/DMSans-Regular.woff2?%REACT_APP_ASSETS_VERSION%') format('woff2');
//    font-display: block;
//}
//
//@font-face {
//    font-family: 'DM';
//    font-weight: 700;
//    src: url('%REACT_APP_ASSETS_PATH%/assets/fonts/DM_Sans/DMSans-Bold.woff2?%REACT_APP_ASSETS_VERSION%') format('woff2');
//    font-display: block;
//}

// @font-face {
//     font-family: 'Noto';
//     font-weight: 400;
//     src: url('/assets/fonts/Noto_Sans/NotoSans-Regular.woff2') format('woff2');
//     font-display: block;
// }


// @font-face {
//     font-family: 'Noto';
//     font-weight: 700;
//     src: url('/assets/fonts/Noto_Sans/NotoSans-Bold.woff2') format('woff2');
//     font-display: block;
// }

@keyframes fadein {
    from { opacity: 0 }
    to { opacity: 1 }
}

[hidden] {
    display: none !important;
}

.file-field-hidden {
    display: none;
}

body {
    font-family: "DM", sans-serif;
    font-size: 16px;
    color: #000;
    background-color: #e5e5e5;
    overflow-x: hidden;
    margin: 0 auto;
    -webkit-tap-highlight-color: transparent;
}

.app-lang--ru {
    font-family: "Noto Sans", sans-serif;
}
.app-lang--ja {
    font-family: "Noto Sans JP", sans-serif;
}
.app-lang--ko {
    font-family: "Noto Sans KR", sans-serif;
}
.app-lang--zh {
    font-family: "Noto Sans SC", sans-serif;
}

.container {
    max-width: 1110px;
    width: 100%;
    box-sizing: border-box;
    padding: 0 15px;
    margin: 0 auto;
}

p {
    line-height: normal;
}

button {
    cursor: pointer;
    position: relative;
    padding: 0;
    input {
        opacity: 0;
        height: 0 !important;
        position: absolute;
        padding: 0 !important;
        margin: 0 !important;
    }
    &[disabled] {
        color: #999;
        background-color: #f3f3f3;
    }
}

a {
    text-decoration: none;
}

input {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

button {
    border: none;
}

/*slider*/
.slick-list,
.slick-slider {
    position: initial;
    display: block;
}

.slick-list {
    overflow: hidden;
    margin: 0;
    padding: 0;
}

.slick-dots {
    position: absolute;
    bottom: -25px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}

.slick-dots {
    li {
        position: relative;
        display: inline-block;
        width: 6px;
        height: 6px;
        cursor: pointer;
        border-radius: 50%;
        padding: 0;
        margin: 0 3px;
    }
    button {
        width: 6px;
        height: 6px;
        cursor: pointer;
        border-radius: 50%;
        padding: 0;
        margin: 0 3px;
        color: transparent;
        border: 0;
        outline: none;
        background: #e0e0e0;
    }
    .slick-active {
        button {
            background: #000;
        }
    }
}

.slick-slide {
    float: left;
    font-size: 0;
}
